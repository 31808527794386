.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 70vh;
  height: calc(var(--doc-height) - 30%);

  color: var(--beige-dark);
}

.form {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 30px;

  width: 50%;
}

.title {
  margin: 60px;

  font-size: 34px;
  font-weight: bold;
  color: var(--default-deep-red);
  text-align: center;
}

.singupGroup {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin: 30px auto 0;
}

.singupGroup:first-of-type {
  margin-top: 60px;
}

.link {
  margin: 0;

  font-size: 20px;
  font-weight: bold;
  color: var(--default-deep-red);
  text-align: center;
  text-decoration: none;
}

.text {
  font-size: 20px;
}

.error {
  position: absolute;
  bottom: -45px;
  left: 0;

  width: 100%;

  font-size: 18px;
  font-weight: bold;
  color: red;
  text-align: center;
}

@media (max-width: 768px) {
  .form {
    width: 80%;
  }

  .link {
    font-size: 18px;
  }

  .text {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .container {
    padding-top: 35px;
  }

  .title {
    margin: 40px;
    font-size: 24px;

    /* font-size: 24px;
    width: 80%;
    text-align: right; */
  }

  .link {
    font-size: 16px;
  }

  .text {
    font-size: 16px;
  }

  .singupGroup {
    flex-direction: column;
    gap: 5px;
  }
}
