.main {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  height: var(--doc-height);

  background: url("../../images/background.png");
  background-size: cover;
}

@media (max-width: 768px) {
  .main {
    background-size: 100%;
  }
}

@media (max-width: 576px) {
  .main {
    background-size: cover;
  }
}
