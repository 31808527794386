.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 70vh;
  height: calc(var(--doc-height) - 30%);

  color: var(--beige-dark);
}

.form {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 30px;

  width: 50%;
}

.title {
  margin: 30px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  margin: 0;

  font-size: 20px;
  font-weight: bold;
  color: var(--default-deep-red);
  text-align: center;
}

.otpCodeGroup {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: center;

  width: 75%;
  margin: auto;
}

@media (max-width: 768px) {
  .form {
    width: 80%;
  }

  .link {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .container {
    padding-top: 35px;
  }

  .title {
    margin: 40px;
    font-size: 24px;

    /* width: 50%;
		text-align: right;
		margin-left: auto; */
  }

  .link {
    font-size: 16px;
  }

  .otpGroup {
    flex-direction: column;
    gap: 5px;
  }

  .otpCodeGroup {
    gap: 5px;
  }

  .subtitle {
    font-size: 16px;
  }
}
