.wrapper,
.content,
.map,
.sphere {
  overflow: hidden;
  display: block;

  width: calc(var(--chat-width) * 0.85);
  height: calc(var(--chat-width) * 0.85);

  border-radius: 50%;
}

.wrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}

.content {
  position: relative;
  top: 0;
  left: 0;

  margin: 0;
  padding: 0;
}

.map {
  position: absolute;
  margin: 0;
  background-color: #ddd6c7;
}

.sphere {
  position: absolute;
  top: 0;
  left: 0;

  margin: 0;

  background-repeat: no-repeat;
  background-position: 0 0;
}

.map_front {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 500%;
  height: 100%;
  margin: 0;

  background-image: url("../../images/mapfront.svg");
  background-repeat: repeat-x;
  background-position: 5% 50%;
  background-size: contain;

  animation: textureSpinreverse 200s linear infinite;
}

.map_back {
  display: block;

  width: 500%;
  height: 100%;
  margin: 0;

  opacity: 0.3;
  background-image: url("../../images/mapback.svg");
  background-repeat: repeat-x;
  background-position: -50% 50%;
  background-size: contain;

  animation: textureSpin 200s linear infinite;
}

@keyframes textureSpin {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-47.5%);
  }
}
@keyframes textureSpinreverse {
  from {
    transform: translateX(-47.5%);
  }

  to {
    transform: translateX(0);
  }
}

@media (max-width: 576px) {
  .wrapper {
    top: 38%;
  }
}
