.container {
  position: relative;
  width: 100%;
}

.input {
  display: block;

  box-sizing: border-box;
  width: 100%;
  height: 38px;
  padding-right: 35px;
  padding-left: 10px;

  font-size: 16px;
  color: var(--background-dark) !important;

  background-color: var(--beige-dark);
  border: 1px solid var(--beige-dark);
  border-radius: var(--border-radius);
  outline: none;
}

.input:focus {
  background-color: var(--beige-dark);
  box-shadow: 5px 5px 10px 1px rgb(234 226 204 / 54%);
}

.label {
  width: 100%;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

.error {
  position: absolute;
  bottom: -25px;
  left: 0;

  font-weight: bold;
  color: red;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
}

input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: textfield;
  appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--beige-dark) inset !important;
}
