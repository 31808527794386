.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 70vh;
  height: calc(var(--doc-height) - 30%);

  color: var(--beige-dark);
}

.form {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 50px;

  width: 50%;
}

.title {
  margin: 40px;
  font-size: 34px;
  font-weight: bold;
  text-align: center;

  /* color: var(--default-deep-red); */
}

.text {
  font-size: 20px;
}

@media (max-width: 768px) {
  .form {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .container {
    padding-top: 35px;
  }

  .title {
    margin: 40px;
    font-size: 24px;

    /* width: 50%;
    text-align: right;
    margin-left: auto; */
  }
}
