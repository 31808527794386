.wrapper {
  width: 100%;
  height: 250px;
  margin: auto;

  background-image: url("../../images/image_icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius);
}
