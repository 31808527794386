.wrapper {
  position: relative;

  flex: none;

  width: 400px;
  height: 95vh;

  /* height: calc(var(--doc-height) - 5%); */

  background-color: var(--beige-dark);
  border-radius: var(--border-radius);
}

.content {
  overflow-x: hidden;
  overflow-y: auto;

  /* max-height: 70vh;
  min-height: 70vh; */
  height: calc(100% - 232px);

  /* display: flex;
  flex-direction: column;
  align-items: center; */

  /* background-color: var(--beige-dark); */
}

.content::-webkit-scrollbar {
  width: 7px;
}

.content::-webkit-scrollbar-track {
  background-color: var(--beige-dark);
}

.content::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  /* width: 100%; */
  height: 60px;
  padding: 10px;

  /* 
  background-image: url('../../images/background\ \(1\).png');
    background-size: 50%;
    background-blend-mode: multiply; */

  /* position: sticky;
  top: 0;
  right: 0;
  background-color: var(--beige-dark);
  border-radius: var(--border-radius); */
}

.avatar {
  align-self: center;

  width: 60px;
  height: 60px;

  /* padding: 10px; */

  object-fit: cover;
  border-radius: 50%;

  /* float: right; */
}

.searchContainer {
  position: relative;
  display: flex;
  width: calc(100% - 127px);
  margin: 78px 10px 7px 110px;
}

.searchInput {
  width: 80%;
  height: 50px;
  padding-right: 50px;
  padding-left: 10px;

  font-size: var(--text-large);

  border: none;
  border-radius: var(--border-radius);
  outline: none;
  box-shadow: none;

  /* -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
	-moz-box-shadow: 0 0 0 30px var(--white) inset !important;
	box-shadow: 0 0 0 30px var(--white) inset !important; */
}

.searchInput::placeholder {
  font-size: var(--text-large);
  color: gray;
}

/* input:-webkit-autofill {
  --webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
} */
.searchIcon {
  /* display: block;
  width: 40px;
  height: 40px; */
  position: absolute;
  right: 15px;
  bottom: 27px;
}

.searchResult {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;

  width: 100%;
  margin-top: 20px;

  /* margin-bottom: 60px; */

  /* justify-content: flex-end; */

  /* justify-content: center;
  height: 100%; */
}

.notification {
  font-size: var(--text-large);

  /* font-weight: bold; */
}

.addContactBtn {
  cursor: pointer;

  margin-right: auto;
  margin-left: auto;
  padding: 10px;

  font-size: 18px;
  font-weight: bold;
  color: var(--white);

  background-color: var(--beige-dark);
  border: 1px solid white;
  border-radius: var(--border-radius);
}

/* .searchResult {
  display: flex;
  flex-direction: row;
} */
@media (max-width: 992px) {
  .wrapper {
    width: 300px;

    /* background-image: url('../../images/background\ \(1\).png');
    background-size: 70%;
    background-blend-mode: multiply;
    background-position: bottom; */
  }

  .searchContainer {
    width: calc(100% - 90px);
    margin: 78px 0 7px 75px;
  }

  .searchInput {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .wrapper {
    width: 250px;
  }
}
@media (max-width: 576px) {
  .wrapper {
    height: 100vh;
    height: var(--doc-height);
    border-radius: 0;
  }

  .content {
    height: calc(100% - 200px);
  }

  .menu {
    width: 95%;
  }

  .searchContainer {
    margin: 45px 0 7px 80px;
  }
}
