.wrapper {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  gap: 15px;

  width: 95%;
  min-height: 70px;
  margin-right: auto;
  margin-left: auto;

  border-bottom: 1px solid var(--beige-light);
  border-radius: var(--border-radius);
}

.details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: calc(100% - 70px);
}

.userInfo {
  width: 80%;
}

.onlineStatus {
  font-size: var(--font-size-default);
}

.name {
  overflow: hidden;

  margin-bottom: 5px;

  font-size: var(--font-size-large);
  text-overflow: ellipsis;
  white-space: nowrap;
}
