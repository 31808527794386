.content {
  display: flex;
  flex-direction: row;
  gap: 15px;

  width: 80%;
  max-width: 1530px;
}

@media (max-width: 1200px) {
  .content {
    width: 90%;
  }
}

@media (max-width: 992px) {
  .content {
    width: 95%;
  }
}

@media (max-width: 576px) {
  .content {
    gap: 0;
    width: 100%;
    height: 100vh;
    height: var(--doc-height);

    /* border-radius: none; */
  }
}
