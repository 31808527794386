@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  src: url("./IBMPlexSans/IBMPlexSans.woff2"), url("./IBMPlexSans/IBMPlexSans.woff");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  src: url("./IBMPlexSans/IBMPlexSans-Medium.woff2"), url("./IBMPlexSans/IBMPlexSans-Medium.woff");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: 700;
  src: url("./IBMPlexSans/IBMPlexSans-Bold.woff2"), url("./IBMPlexSans/IBMPlexSans-Bold.woff");
}
