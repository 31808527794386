.wrapper {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  transform-origin: right top;

  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 350px;
  height: 370px;
  margin: 0;
  padding: 20px;

  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 4px 3px 20px -1px rgb(34 60 80 / 20%);
}

.userDetails {
  position: relative;
  display: flex;
  flex-direction: row;
}

.userName {
  overflow: hidden;
  justify-content: flex-start;

  width: 200px;

  font-size: 20px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.email {
  justify-content: flex-start;
}

.avatar {
  display: flex;
  justify-content: center;

  max-width: 100%;
  height: 300px;

  object-fit: cover;
  object-position: top;
  border-radius: var(--border-radius);
}

.shareContactBtn {
  cursor: pointer;

  padding: 7px;

  font-size: 14px;
  font-weight: bold;
  color: #ddd6c7;
  text-align: start;

  background-color: var(--white);
  border: 1px solid #ddd6c7;
  border-radius: var(--border-radius);
}

@media (max-width: 576px) {
  .wrapper {
    top: 0;
    right: 0;

    width: 95%;
    height: auto;
    padding: 10px;
  }

  .avatar {
    height: 220px;
    object-fit: contain;
  }
}
