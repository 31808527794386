.button {
  cursor: pointer;

  position: relative;
  transform: rotate(0deg);

  width: 40px;
  height: 40px;
  margin-right: 20px;

  transition: 0.5s ease-in-out;
}

.button span {
  position: absolute;
  left: 0;
  transform: rotate(0deg);

  display: block;

  width: 100%;
  height: 6px;

  opacity: 1;
  background: var(--white);
  border-radius: 6px;

  transition: 0.25s ease-in-out;
}

.button span:nth-child(1) {
  top: 0;
}

.button span:nth-child(2),
.button span:nth-child(3) {
  top: 12px;
}

.button span:nth-child(4) {
  top: 24px;
}

.button.open span:nth-child(1) {
  top: 12px;
  left: 50%;
  width: 0%;
}

.button.open span:nth-child(2) {
  transform: rotate(45deg);
}

.button.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.button.open span:nth-child(4) {
  top: 18px;
  left: 50%;
  width: 0%;
}
