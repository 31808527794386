.wrapper {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;

  padding: 4px;

  background-color: var(--white);
  border-radius: 50%;
}
