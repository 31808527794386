.wrapper {
  position: absolute;
  z-index: 100;
  top: -77px;
  left: 0;

  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  height: 70px;

  background-color: var(--beige-light);
  border-radius: var(--border-radius);

  box-shadow: 0 18px 14px -2px rgb(253 248 236 / 80%), 0 -6px 14px -4px rgb(0 0 0 / 10%);
}

.message {
  overflow: hidden;
  display: -webkit-box;

  width: 90%;
  margin: 10px;

  font-size: var(--font-size-default);
  color: gray;
  text-align: justify;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.image {
  display: block;

  height: 90%;
  margin: 5px auto 0;

  object-fit: contain;
  border-radius: var(--border-radius);
}

.pdf {
  margin: 5px auto;
  border-radius: var(--border-radius);
}

.file {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  width: 7vh;
  height: 7vh;
  margin: 5px auto 0;
  padding: 0 3px 3px;

  background: url("../../images/file.svg"), var(--beige-dark);
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: 70%;
  border-radius: var(--border-radius);
}

.fileName {
  overflow: hidden;

  width: 90%;

  font-size: var(--font-size-small);
  font-weight: bold;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-top: 10px;
}

.button {
  flex: none;

  width: 25px;
  height: 25px;

  background-color: transparent;
  background-image: url("../../images/cross.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  border-color: transparent;
  border-radius: var(--border-radius);
}
