.wrapper {
  position: absolute;
  top: 3px;
  right: 10px;

  width: 30px;
  height: 45px;
}

.loader {
  position: relative;

  display: flex;

  box-sizing: border-box;
  width: 100%;
  height: 100%;

  background: linear-gradient(#f0eadd 15px, transparent 0) no-repeat;
  background-position: 50% 0;
  background-size: 2px 40px;
  border-top: 5px solid #c1bdb5;
  border-bottom: 5px solid #c1bdb5;

  animation: spinx 5s linear infinite;
}

.loader::before,
.loader::after {
  content: "";

  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  width: 100%;
  height: 50%;

  background: rgb(221 214 163 / 40%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  border-radius: 0 0 20px 20px;

  animation: lqt 5s linear infinite;
}

.loader::after {
  top: auto;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  animation: lqb 5s linear infinite;
}
@keyframes lqt {
  0%,
  100% {
    background-image: linear-gradient(#dfdcc8 40px, transparent 0);
    background-position: 0% 0;
  }

  50% {
    background-image: linear-gradient(#dfdcc8 40px, transparent 0);
    background-position: 0% 40px;
  }

  50.1% {
    background-image: linear-gradient(#dfdcc8 40px, transparent 0);
    background-position: 0% -40px;
  }
}
@keyframes lqb {
  0% {
    background-image: linear-gradient(#dfdcc8 40px, transparent 0);
    background-position: 0 40px;
  }

  100% {
    background-image: linear-gradient(#dfdcc8 40px, transparent 0);
    background-position: 0 -40px;
  }
}
@keyframes spinx {
  0%,
  49% {
    transform: rotate(0deg);
    background-position: 50% 36px;
  }

  51%,
  98% {
    transform: rotate(180deg);
    background-position: 50% 4px;
  }

  100% {
    transform: rotate(360deg);
    background-position: 50% 36px;
  }
}
