.button {
  cursor: pointer;

  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;

  height: 50px;
  padding: 10px 15px;

  font-weight: bold;
  color: var(--background-dark);
  background-color: var(--beige-dark);
  background-size: contain;
  border: none;
  border-radius: 15px;
  box-shadow: -4px -3px 11px 0 rgb(88 98 120 / 68%) inset, 4px 4px 11px 0 rgb(88 98 120 / 68%) inset;
}

.button:disabled {
  background-size: contain;
  background-blend-mode: overlay;
  box-shadow: 5px 5px 15px rgb(217 200 200);
}
