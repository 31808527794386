.wrapper {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;

  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 350px;
  max-height: 525px;
  padding: 20px 0 0;

  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 4px 3px 20px -1px rgb(34 60 80 / 20%);
}

.wrapper::-webkit-scrollbar {
  width: 7px;
}

.wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
}

.title {
  display: block;
  padding-bottom: 20px;
  font-size: var(--text-large);
  font-weight: bold;
}

.list {
  width: 100%;
  margin: 0;
  padding-left: 0;

  list-style: none;

  background-color: var(--white);

  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.list__item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  height: 60px;
  padding-right: 10px;
  padding-left: 10px;

  border-top: 1px solid var(--beige-dark);
}

.text {
  overflow: hidden;

  width: 200px;

  font-size: var(--font-size-large);
  text-overflow: ellipsis;
  white-space: nowrap;
}
