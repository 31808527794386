.container {
  position: absolute;
  z-index: 15;
  top: 20px;
  right: 20px;

  transform-origin: right top;

  display: flex;
  flex-direction: column;

  width: 390px;
  margin: 0;
  padding: 20px 0;

  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 4px 3px 20px -1px rgb(34 60 80 / 20%);
}

.groupName {
  margin-top: 15px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.avatar {
  max-width: 90%;
  height: 250px;
  margin: 0 auto 20px;

  object-fit: cover;
  object-position: top;
  border-radius: var(--border-radius);
}

.contacts {
  --moz-scrollbar-color: #eae2cc #fff;
  --moz-scrollbar-width: thin;
  overflow-y: scroll;

  width: 100%;
  max-height: 240px;
  margin-top: 0;
  padding: 0;

  list-style: none;
}

.contacts::-webkit-scrollbar {
  width: 4px;
}

.contacts::-webkit-scrollbar-track {
  background-color: transparent;
}

.contacts::-webkit-scrollbar-thumb {
  background-color: var(--beige-dark);
  border-radius: 3px;
}

.contact {
  position: relative;
  border-bottom: 1px solid var(--beige-dark);
}

.text {
  overflow: hidden;

  width: 170px;

  font-size: var(--font-size-large);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  margin-right: 20px;
  margin-left: 20px;
}

.input {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 10px;

  font-size: 16px;
  font-size: var(--font-size-large);
  text-align: justify;
  text-overflow: ellipsis;
  white-space: nowrap;

  border: 1px solid var(--beige-dark);
  border-radius: var(--border-radius);
  outline: none;
}

.input::placeholder {
  font-size: var(--font-size-large);
}

.buttonSend {
  cursor: pointer;
  position: relative;

  overflow: hidden;
  display: block;

  box-sizing: content-box;
  width: 32px;
  height: 32px;
  margin-bottom: 5px;

  background-color: white;
  border: none;
  border-radius: 50%;
}

.addUserPopup {
  position: absolute;
  z-index: 25;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 90%;
  max-height: 370px;
  padding: 0 10px 60px;

  background-color: var(--beige-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-down);
}

.contacts__item {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;

  height: 60px;
  padding: 10px;

  border-bottom: 1px solid var(--white);
}

.addContactBtn {
  cursor: pointer;

  z-index: 20;
  width: 210px;
  height: 40px;
  margin: 5px auto;
  padding: 0 10px;

  font-size: 18px;
  font-weight: bold;
  color: #ddd6c7;

  background-color: var(--white);
  border: 1px solid var(--beige-dark);
  border-radius: var(--border-radius);
}

@media (max-width: 576px) {
  .container {
    top: 0;
    right: 0;
    width: 100%;
    padding: 10px 0;
  }

  .contacts {
    max-height: 260px;
  }

  .input {
    margin-bottom: 0;
  }

  .groupName {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .avatar {
    height: 120px;
    object-fit: contain;
  }

  .buttonSend {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .addContactBtn {
    font-size: 16px;
  }
}
