.wrapper {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgb(0 0 0 / 40%);
}
