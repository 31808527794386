:root {
  // Цвета
  --text-dark: rgb(16 12 52 / 100%);
  --text-light: rgb(163 163 163 / 100%);
  --text-white: rgb(240 240 240 / 100%);
  --background-dark: rgb(38 68 92 / 100%);

  // --background-color: rgb(38 68 92 / 97%);
  --beige-dark: rgb(234 226 204);
  --beige-light: rgb(248 243 233);
  --green: rgb(37 186 45);
  --white: rgb(252 252 252 / 100%);
  --border-radius: 10px;
  --box-shadow-down: 10px 7px 13px 0 rgb(5 6 6 / 20%);
  --box-shadow-up: -10px -7px 13px 0 rgb(5 6 6 / 20%);

  // Шрифт
  --text-font-family: "IBM Plex Sans", arial, helvetica, sans-serif;
  --font-size-default: 14px;
  --font-size-large: 16px;
  --font-size-small: 12px;
}
