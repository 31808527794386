.wrapper {
  position: absolute;
  top: 75px;
  left: 7%;
  transform-origin: right top;

  width: 86%;
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-down);
}

.list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.list__item {
  cursor: pointer;

  transform-origin: bottom;

  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  height: 50px;
  padding-right: 20px;
  padding-left: 20px;

  font-size: var(--text-large);

  border-bottom: 1px solid var(--beige-dark);
}

.list__item:nth-child(2) {
  border-bottom: none;
}

.list__item:nth-child(3) {
  border-top: 1px solid var(--beige-dark);
}

.list__item:nth-child(4) {
  border-bottom: none;
}

.form {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contacts {
  --moz-scrollbar-color: #eae2cc #fff;
  --moz-scrollbar-width: thin;
  overflow-y: scroll;

  width: 100%;
  max-height: 300px;
  margin: 0;
  padding-left: 0;

  list-style: none;

  background-color: var(--white);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.contacts::-webkit-scrollbar {
  width: 4px;
}

.contacts::-webkit-scrollbar-track {
  background-color: transparent;
}

.contacts::-webkit-scrollbar-thumb {
  background-color: var(--beige-dark);
  border-radius: 3px;
}

.contacts__item {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  height: 60px;

  border-top: 1px solid var(--beige-dark);
}

.contacts__item:nth-child(1) {
  margin-top: 5px;
  border-top: none;
}

.details {
  overflow: hidden;

  width: 150px;

  font-size: var(--font-size-large);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.input {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 10px;

  font-size: 16px;
  font-size: var(--font-size-large);
  text-align: justify;
  text-overflow: ellipsis;
  white-space: nowrap;

  border: 1px solid var(--beige-dark);
  border-radius: var(--border-radius);
  outline: none;
}

.input::placeholder {
  font-size: var(--font-size-large);
}

.buttonSend {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: block;

  box-sizing: content-box;
  width: 32px;
  height: 32px;
  margin-bottom: 5px;

  background-color: white;
  border: none;
  border-radius: 50%;
}

.buttonSend:disabled {
  cursor: auto;
  opacity: 0.5;
}

.warning {
  width: 100%;
  font-size: var(--font-size-large);
  color: gray;
  text-align: center;
}
@media (max-width: 768px) {
  .details {
    font-size: var(--font-size-default);
  }
}
@media (max-width: 576px) {
  .contacts {
    max-height: 235px;
  }
}
