.imageVisible {
  cursor: pointer;

  display: block;

  min-width: 10vw;
  max-width: 90%;
  max-height: 30vh;
  margin: 15px auto;

  object-fit: contain;
  border-radius: var(--border-radius);
}

.imageHidden {
  display: none;
}

.imageLoader {
  display: flex;
  align-items: center;

  width: 15vw;
  height: 20vw;
  margin: auto;
  background: transparent;
}

.video {
  cursor: pointer;

  min-width: 10vw;
  max-width: 100%;
  margin: 15px auto;

  border-radius: var(--border-radius);
}

.pdf {
  cursor: pointer;

  justify-self: center;

  max-width: 20vw;
  margin: 25px auto 15px;

  border-radius: var(--border-radius);
}

.file {
  cursor: pointer;

  position: relative;

  display: flex;
  display: block;
  align-items: flex-end;
  justify-content: center;

  width: 10vh;
  height: 10vh;
  margin: 25px auto 15px;

  background: url("../../images/file.svg"), var(--beige-light);
  background-color: var(--beige-light);
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: 70%;
  border-radius: var(--border-radius);
}

.fileName {
  overflow: hidden;

  width: 95%;
  padding: 70px 0 0;

  font-size: var(--font-size-default);
  font-weight: bold;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.downLoad {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  transition: all 0.3s linear;
}

@media (max-width: 576px) {
  .image {
    max-height: 50vh;
  }

  .file {
    width: 15vh;
    height: 15vh;
  }

  .imageLoader {
    width: 40vw;
    height: 40vw;
  }
}
