.input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}

.label {
  cursor: pointer;

  position: absolute;
  right: 5px;
  bottom: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 42px;
  height: 42px;

  background-color: var(--white);
  border: none;
  border-radius: 50%;
}

.container {
  overflow-x: hidden;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  /* grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr); */
  gap: 40px 40px;
  align-items: center;
  justify-items: center;

  min-width: 40vw;
  max-width: 60vw;
  min-height: 60vh;
  max-height: 75vh;
  margin-bottom: 30px;
  padding: 30px;

  /* background-color: var(--beige-light); */
}

.container::-webkit-scrollbar {
  width: 7px;
}

.container::-webkit-scrollbar-track {
  background-color: rgb(245 240 240 / 35.6%);
}

.container::-webkit-scrollbar-thumb {
  background-color: var(--beige-light);
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
}

.image {
  max-width: 90%;
  max-height: 30vh;

  object-fit: contain;
  background-color: var(--beige-light);
  border-radius: var(--border-radius);
}

.pdf {
  border-radius: var(--border-radius);
}

.pdf canvas {
  object-fit: contain;
  border-radius: var(--border-radius);
}

.video {
  max-width: 90%;
  max-height: 30vh;
  border-radius: var(--border-radius);
}

.file {
  position: relative;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  width: 15vw;
  height: 15vw;

  background: url("../../images/file.svg"), var(--beige-light);
  background-color: var(--beige-light);
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: 70%;
  border-radius: var(--border-radius);
}

.fileName {
  overflow: hidden;

  width: 90%;
  padding: 0 0 15px;

  font-size: var(--text-large);
  font-weight: bold;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.warning {
  font-size: var(--text-large);
  color: var(--white);
  text-align: center;
}
@media (max-width: 992px) {
  .container {
    max-width: 85vw;
  }
}

@media (max-width: 768px) {
  .file {
    width: 20vw;
    height: 20vw;
  }
}

@media (max-width: 576px) {
  .file {
    width: 40vw;
    height: 40vw;
  }
}
