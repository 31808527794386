.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.wrapper {
  overflow: hidden;
  max-width: 300px;
  height: 180px;
  border-radius: var(--border-radius);
}

.loader {
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;
}

.input_hidden {
  position: absolute;
  z-index: -1;

  display: block;

  width: 0;
  height: 0;

  opacity: 0;
}

.label {
  position: relative;

  display: flex;
  flex-direction: column;

  width: inherit;
  height: inherit;

  background-color: white;
}

.image {
  height: 100%;
  object-fit: cover;
}

.button {
  cursor: pointer;

  position: absolute;
  z-index: 5;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 30%;

  background: #d5cbb25e;

  transition: all 0.2s linear;
}

.button_default {
  height: 30%;
  visibility: visible;
  opacity: 1;
}

.button_active {
  height: 0;
  visibility: hidden;
  opacity: 0;
}

@media (max-width: 992px) {
  .wrapper {
    max-width: 90%;
  }
}

@media (max-width: 576px) {
  .wrapper {
    height: 120px;
  }
}
