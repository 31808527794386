.wrapper {
  position: relative;

  width: fit-content;

  /* z-index: 2; */

  /* max-width: 60%; */
  min-width: 115px;
  padding: 10px 10px 25px;

  background-color: var(--white);
  border-radius: var(--border-radius);

  /* box-shadow: -1px -4px 18px 0 rgb(102 105 82 / 40%); */
  box-shadow: -6px 4px 18px 0 rgb(102 105 82 / 30%);

  /* max-height: 200px;
  overflow: hidden; */

  /* display: -webkit-box;
	-webkit-line-clamp:8;
	-webkit-box-orient: vertical;
	overflow: hidden; */
}

.text {
  cursor: pointer;

  position: relative;

  /* white-space: pre; */

  /* display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical; */
  overflow: hidden;

  /* max-height: 70px; */
  overflow-y: hidden;

  font-size: var(--font-size-default);
  line-height: 18px;
  word-wrap: break-word;
  white-space: pre-wrap;

  transition: all 0.1s linear;
}

.timeStamp {
  position: absolute;
  bottom: 7px;
  font-size: var(--font-size-small);
  color: gray;
}

.reaction {
  cursor: pointer;

  position: absolute;
  bottom: -15px;

  display: flex;
  flex-direction: row;

  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: -1px -4px 18px 0 rgb(102 105 82 / 20%);
}

.counter {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.count {
  padding-right: 3px;

  /* position: absolute;
  bottom: 0;
  right: 0; */
  font-size: var(--font-size-small);
}

.popup {
  cursor: default;

  position: absolute;
  z-index: 300;
  top: 50%;

  width: 300px;
  height: auto;
  max-height: 400px;

  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 4px 3px 20px -1px rgb(34 60 80 / 20%);

  /* transform-origin: right top; */
}

.summary_list {
  --moz-scrollbar-color: #eae2cc #fff;
  --moz-scrollbar-width: thin;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  max-height: 250px;
  margin: 0 0 0 7px;
  padding: 0;

  list-style: none;
}

.summary_list::-webkit-scrollbar-track {
  background-color: transparent;
}

.summary_list::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.summary_list::-webkit-scrollbar-thumb {
  background-color: #ddd6c7;
  border-radius: 100px;
  border-radius: var(--border-radius);
}

.summary_item {
  position: relative;

  display: flex;
  flex: none;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  width: 95%;
  height: 50px;
  margin: auto;

  border-bottom: 1px solid var(--beige-dark);

  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */

  /* font-size: var(--font-size-small); */
}

.summary_item:last-of-type {
  border-bottom: none;
}

.summary_reaction {
  font-size: 22px;
}

.summary_name {
  overflow: hidden;
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.summary_avatar {
  width: 40px;
  height: 40px;
  margin-left: auto;

  object-fit: cover;
  border-radius: 50%;
}

.actions {
  cursor: pointer;

  position: absolute;
  z-index: 3000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  width: fit-content;
  margin: 0;
  padding: 0;

  white-space: nowrap;
  list-style: none;

  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 4px 3px 20px -1px rgb(34 60 80 / 20%);
}

.action {
  display: flex;
  align-items: center;

  height: 40px;
  padding-right: 10px;
  padding-left: 10px;

  border-bottom: 1px solid var(--beige-dark);
}

.emoji {
  z-index: 100;
  top: 50%;

  /* left: 50%; */
  transform: translate(0, -50%);
  box-shadow: var(--box-shadow-up);

  /* box-shadow: -10px -7px 13px 0 rgb(5 6 6 / 20%);
  box-shadow: -10px -7px 13px 0 rgb(5 6 6 / 20%); */
}

.hideButton {
  cursor: pointer;

  position: absolute;
  bottom: 5px;

  display: block;

  padding: 2px 5px;

  font-size: var(--font-size-default);
  font-weight: bold;
  color: gray;
}

.forwarded {
  display: flex;
  align-items: center;
  font-size: var(--font-size-small);
  color: gray;
}

.authorContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.author_avatar {
  flex: none;

  width: 24px;
  height: 24px;

  /* margin-left: auto; */

  object-fit: cover;
  border-radius: 50%;
}

.author_name {
  overflow: hidden;

  width: fit-content;
  max-width: 120px;

  font-size: var(--font-size-small);
  color: gray;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete_reaction {
  cursor: pointer;

  position: absolute;
  top: 10px;
  right: 75px;

  width: 20px;

  font-size: var(--font-size-small);
  color: gray;
}

.deletedMessage {
  display: block;

  width: 24px;
  height: 24px;
  margin: auto;
  padding: 3px;

  background-color: var(--white);
  border-radius: var(--border-radius);
}

@media (max-width: 768px) {
  .popup {
    width: 240px;
  }

  .summary_name {
    width: 30%;
  }
}

@media (max-width: 576px) {
  .popup {
    width: 70vw;
  }

  /* .summary_name {
    width: 50%;
  } */
}
