.button {
  cursor: pointer;

  position: absolute;

  width: 42px;
  height: 42px;
  padding: 3px;

  background-color: var(--white);
  border: none;
  border-radius: 50%;
}
