.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.input + label::before {
  content: "";

  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;

  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5em;

  background-color: var(--white);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70% 70%;
  border: 2px solid #c1ae76a1;
  border-radius: 0.25em;
}

.input:checked + label::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23c1ae76' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
