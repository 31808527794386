.loader {
  position: relative;

  width: 3px;
  height: 3px;
  margin-top: 5px;

  background-color: #a8956c;
  border-radius: 50%;
  box-shadow: 6px 0 #8a7d62, -6px 0 #8b7e60;

  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: rgb(225 219 211);
    box-shadow: 6px 0 rgb(225 219 211), -6px 0 #a8956c;
  }

  50% {
    background-color: #a8956c;
    box-shadow: 6px 0 rgb(225 219 211), -6px 0 rgb(225 219 211);
  }

  100% {
    background-color: rgb(225 219 211);
    box-shadow: 6px 0 #a8956c, -6px 0 rgb(225 219 211);
  }
}
