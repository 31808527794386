.textarea {
  --moz-scrollbar-color: #eae2cc #fff;
  --moz-scrollbar-width: thin;
  resize: none;

  overflow-y: scroll;
  display: flex;

  box-sizing: border-box;
  width: 100%;
  padding: 0 65px 0 70px;

  font-size: 16px;
  text-align: justify;

  border: none;
  border-radius: var(--border-radius);
  outline: none;
}

.textarea::-webkit-scrollbar-track {
  background-color: transparent;
}

.textarea::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.textarea::-webkit-scrollbar-thumb {
  background-color: #ddd6c7;
  border-radius: 100px;
  border-radius: var(--border-radius);
}
