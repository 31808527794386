.wrapper {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;

  transform-origin: right top;

  overflow: hidden;

  width: 370px;
  height: 370px;
  margin: 0;
  padding: 10px;

  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 4px 3px 20px -1px rgb(34 60 80 / 20%);
}

.inputContainer {
  width: 90%;
  margin: auto;
  padding-top: 15px;
}

.list {
  --moz-scrollbar-color: #eae2cc #fff;
  --moz-scrollbar-width: thin;

  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;

  align-items: center;

  max-height: 270px;
  padding: 10px 0 20px;

  list-style: none;
}

.list::-webkit-scrollbar {
  width: 4px;
}

.list::-webkit-scrollbar-track {
  background-color: transparent;
}

.list::-webkit-scrollbar-thumb {
  background-color: var(--beige-dark);
  border-radius: 3px;
}

.list__item {
  cursor: pointer;

  display: flex;
  flex: none;
  flex-direction: column;
  justify-content: center;

  width: 90%;

  height: 50px;
  margin: auto;

  font-size: var(--text-large);

  border-radius: var(--border-radius);

  box-shadow: 2px 4px 13px 4px rgb(102 105 82 / 20%);
}

.form {
  overflow: hidden;
  width: 90%;
}

.date {
  margin-left: 15px;
  font-size: var(--font-size-small);
  font-weight: bold;
  color: #c7ab6e;
}

.message {
  overflow: hidden;

  margin-right: 15px;
  margin-left: 15px;

  font-size: var(--font-size-default);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noResult {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin: 60px auto 0;
}

@media (max-width: 576px) {
  .wrapper {
    top: 0;
    right: 0;
    width: 95%;
  }
}
