.wrapper {
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  width: 95%;
  height: 60px;
  margin: auto;

  border-radius: var(--border-radius);
}

.details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 90%;
}

.nameContainer {
  width: 150px;
}

.name {
  overflow: hidden;
  flex: none;

  margin-bottom: 5px;

  font-size: var(--font-size-large);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.role {
  margin-left: auto;
  font-size: var(--font-size-defaut);
  font-weight: bold;
  color: #ddd6c7;
}

.addToAdminButton {
  cursor: pointer;

  position: absolute;
  top: 7px;
  right: 0;

  display: inline-block;

  width: 90px;
  height: 42px;
  margin-left: auto;
  padding: 0 5px;

  font-size: var(--font-size-default);
  font-weight: bold;
  hyphens: auto;

  color: #ddd6c7;
  -ms-word-break: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;

  background-color: var(--white);
  border: 1px solid #ddd6c7;
  border-radius: var(--border-radius);
}

.addToAdminButton:disabled {
  opacity: 0.5;
  cursor: auto;
}

.deleteLoader {
  position: absolute;
  top: 5px;
  right: 90px;
}
@media (max-width: 576px) {
  .wrapper {
    height: 50px;
  }

  .addToAdminButton {
    top: 4px;
    font-size: 12px;
  }

  .role {
    font-size: 14px;
  }

  .name {
    font-size: var(--font-size-default);
  }
  .deleteLoader {
    top: 1px;
  }
}
