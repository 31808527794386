.button {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: calc(var(--doc-height) - 180px);
  right: 15px;

  display: block;
}

@media (max-width: 414px) {
  .button {
    top: calc(var(--doc-height) - 130px);
  }
}
